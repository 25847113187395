<template>
        <trailer-modal v-if="displayModal" @interface="getTrailerModalInterface"
                       :post="activeTrailer"
                       :offset="previewPos"
                       @open="onModalOpen"
                       @close="onModalClose"/>
</template>

<script>
import {find as _fine} from "lodash";
import store from "../store";
import storeTrailer from "../storeTrailer";
import eventBus from "../eventBus";
import VideoOverview from "../components/videoOverview";
import text from "../text.json"
import routeMixin from "../mixins/routeMixin";
// import TheCarousel from "../components/TheCarousel.vue";
// import ThumbTrailer from "../components/ThumbTrailer.vue";
import TrailerModal from "../components/TrailerModal.vue";
import requestMixin from "../mixins/requestMixin";
import BaseOverlay from "../components/baseOverlay.vue";

export default {
    name: "TrailersModal",
    components: {TrailerModal},
    mixins: [routeMixin, requestMixin],
    props: {transitionEntered: Boolean},
    data() {
        return {
            activeTrailer: null,
            openModal: false,
            previewModal: false,
            previewPos: {},
            displayModal: false
        }
    },
    computed: {
        // activeTrailer() {
        //     return storeTrailer.activeTrailer
        // },
        // previewPos() {
        //     return storeTrailer.previewPos
        // },
        // openModal() {
        //     return storeTrailer.open
        // },
        // previewModal() {
        //     return storeTrailer.preview
        // },
        trailers() {
            return store.trailers
        }
    },

    watch: {
        transitionEntered(val) {
            console.log(val)
            this.displayCarousel = val
        },

        // openModal(val) {
        //     if (val) {
        //         this.onTrailerClick()
        //     }
        // },
        //
        // previewModal(val) {
        //     if (val) {
        //         this.onTrailerHover()
        //     } else {
        //         this.onTrailerLeave()
        //     }
        // }
    },

    activated() {
        // eslint-disable-next-line no-empty
        if (this.$route.params.trailer && this.$route.params.trailerBack) {
        } else {
            this.displayModal = false
            this.activeTrailer = null
            this.openModal = false
            eventBus.$emit('modalClose')
            this.$nextTick(() => {
                this.displayModal = true
            })
        }
    },

    mounted() {
        eventBus.$on("onTrailerClick", (payload) => {
            this.activeTrailer = payload.activeTrailer
            console.log(payload)
            this.onTrailerClick()
        })

        eventBus.$on("onTrailerHover", (payload) => {
            this.activeTrailer = payload.activeTrailer
            this.previewPos = payload.previewPos
            this.onTrailerHover()
            console.log(payload)
        })

        eventBus.$on("onTrailerLeave", () => {
            this.onTrailerLeave()
        })
    },

    methods: {

        getTrailerModalInterface(modalInterface) {
            this.$options.modalInterface = modalInterface
        },

        onTrailerClick() {
            if (this.openModal) {
                return
            }
            this.openModal = true

            this.$nextTick(() => {
                this.$options.modalInterface.showPopup()
            })
        },

        onTrailerHover() {
            if (this.openModal) {
                return
            }

            this.previewModal = true

            this.$nextTick(() => {
                this.$options.modalInterface.showPreview();
            })
        },

        onTrailerLeave() {
            this.previewModal = false
            if (this.openModal) {
                return
            }

            this.$options.modalInterface.closePreview()
        },

        onModalClose() {
            this.openModal = false
            this.activeTrailer = null
            eventBus.$emit('modalClose')
        },

        onModalOpen() {
            this.openModal = true
            eventBus.$emit('modalOpen')
        },
        //
        // sliderMove() {
        //     this.onTrailerLeave()
        //     this.openModal = true
        // },
        // sliderMoved() {
        //     this.openModal = false
        // },
    },

    modalInterface: {
        showPopup: () => {
        },
        closePopup: () => {
        },
        showPreview: () => {
        },
        closePreview: () => {
        }
    }
}
</script>

<style scoped lang="scss">

</style>