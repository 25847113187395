<script>
import KUTE from "kute.js";
import VideoPlayer from "./videoPlayer.vue";
import routeMixin from "../mixins/routeMixin";
import text from "../text.json";
import IconPlayCircle from "../icons/iconPlayCircle.vue";
import store from "../store";

export default {
    data() {
        return {
            CONST: {
                SHOW: 'show',
                PREVIEW: 'preview'
            },
            mute: true,
            pause: true,
            preview: false,
            show: false,
            shown: false,
            canplay: false,
            container: {},
            entered: false,
            state: 'preview',
            n: 1.33,//scale coefficient
        }
    },
    components: {IconPlayCircle, VideoPlayer},
    mixins: [routeMixin],
    props: {post: Object, offset: Object},
    computed: {
        text() {
            return text;
        },
        labels(){
            return store.labels;
        },
        kute() {
            return {
                full: {
                    translateX: this.offset.x - this.container.x - ((this.container.width - this.offset.width) * 0.5),
                    translateY: this.offset.y - this.container.y - (this.offset.height * (this.n - 1) + 31),
                    scale: this.round(this.n * this.offset.width / this.container.width),
                    opacity: 1
                }
            }
        }
    },
    mounted() {
        this.emitInterface()
    },
    methods: {
        round(x) {
            return Math.round(x * 1000) / 1000
        },
        muteToggle() {
            this.mute = !this.mute
        },
        checkCanPlay() {
            this.canplay = true;

            if (this.preview || this.show) {
                if (this.pause) {
                    this.pause = false
                }
            }
        },
        open() {
            this.state = this.CONST.SHOW//'show'
            this.preview = false
            this.$nextTick(() => {
                this.show = true
            })

            this.$emit('open')
        },
        openPreview() {
            this.state = this.CONST.PREVIEW//'preview'
            this.preview = true
        },
        close() {
            this.pause = true
            this.shown = false
            this.show = false
            this.preview = false
            this.canplay = false

            setTimeout(() => {
                this.state = this.CONST.PREVIEW;

            }, 100)
        },
        closeDone() {
            this.$emit('close')
        },

        closePreview() {
            this.preview = false
        },

        /**
         * Emitting an interface with callable methods from outside
         */
        emitInterface() {
            this.$emit("interface", {
                showPopup: () => {
                    console.log('Open popup')
                    this.open()
                },
                closePopup: () => {
                    this.close()
                },
                showPreview: () => {
                    this.openPreview()
                },
                closePreview: () => {
                    this.closePreview()
                }
            });
        },

        //Transitions

        beforeEnter: function (el) {
            if (this.$refs.container) {
                this.container = {
                    x: this.$refs.container.getBoundingClientRect().x,
                    y: this.$refs.container.getBoundingClientRect().y,
                    width: this.$refs.container.getBoundingClientRect().width
                }

                el.style.top = this.offset.y - this.container.y
            }

            el.style.opacity = 0
        },

        enter: function (el, done) {
            if (this.state === this.CONST.SHOW) {
                this.$nextTick(() => {
                    this.enterShow(el, done)
                })

            }
            if (this.state === this.CONST.PREVIEW) {
                this.$nextTick(() => {
                    this.enterPreview(el, done)
                })
            }
        },
        enterPreview: function (el, done) {
            let tween = KUTE.fromTo(el,
                {
                    translateX: this.offset.x - this.container.x - ((this.container.width - this.offset.width) * 0.5),
                    translateY: this.offset.y - this.container.y - 1,
                    scale: Math.round(this.offset.width / this.container.width * 1000) / 1000,
                    opacity: 1
                },
                {
                    translateX: this.offset.x - this.container.x - ((this.container.width - this.offset.width) * 0.5),
                    translateY: this.offset.y - this.container.y - (this.offset.height * (this.n - 1) + 31), //~80
                    scale: this.round(this.n * this.offset.width / this.container.width),
                    opacity: 1
                },
                {duration: 250, easing: 'ease', onComplete: done})

            tween.start()
        },
        enterShow: function (el, done) {
            let from = {
                translateX: 0,
                translateY: 0,
                scale: 1,
                opacity: 1
            }
            let duration = 100;
            if (this.container && this.container.width && this.offset && this.offset.width) {
                from = {
                    translateX: this.offset.x - this.container.x - ((this.container.width - this.offset.width) * 0.5),
                    translateY: this.offset.y - this.container.y - (this.offset.height * (this.n - 1) + 31),
                    scale: this.round(this.n * this.offset.width / this.container.width),
                    opacity: 1
                }
                duration = 400
            }
            let tween = KUTE.fromTo(el,
                from,
                {
                    translateX: 0,
                    translateY: 0,
                    scale: 1,
                    opacity: 1
                },
                {duration, easing: 'easingCubicOut', onComplete: done})

            tween.start()
        },

        afterEnter: function (el) {
            this.entered = true

            if (this.state === this.CONST.SHOW) {
                this.shown = true
            }

            setTimeout(() => {
                if ((this.preview || this.show) && this.canplay) {
                    this.pause = false
                }
            }, 300)
        },

        leaveShow: function (el, done) {
            let to = {
                translateX: 0,
                translateY: 0,
                scale: 1.2,
                opacity: 0
            };
            if (this.container && this.container.width && this.offset && this.offset.width) {
                // to = {
                //     translateX: this.offset.x - this.container.x - ((this.container.width - this.offset.width) * 0.5),
                //     translateY: this.offset.y - this.container.y - (this.offset.height * (this.n - 1) + 31),
                //     scale: this.round(this.n * this.offset.width / this.container.width),
                //     opacity: 1
                // }

                to = {
                    translateX: this.offset.x - this.container.x - ((this.container.width - this.offset.width) * 0.5),
                    translateY: this.offset.y - this.container.y - 1,
                    scale: Math.round(this.offset.width / this.container.width * 1000) / 1000,
                    opacity: 1
                }
            }
            let tween = KUTE.fromTo(el,
                {
                    translateX: 0,
                    translateY: 0,
                    scale: 1,
                    opacity: 1
                },
                to,
                {duration: 300, easing: 'easingCubicOut', onComplete: done})

            tween.start()
        },

        leavePreview(el, done) {
            let tween = KUTE.fromTo(el,
                {
                    translateX: this.offset.x - this.container.x - ((this.container.width - this.offset.width) * 0.5),
                    translateY: this.offset.y - this.container.y - (this.offset.height * (this.n - 1) + 31), //~80
                    scale: this.round(this.n * this.offset.width / this.container.width),
                    opacity: 1
                },
                {
                    translateX: this.offset.x - this.container.x - ((this.container.width - this.offset.width) * 0.5),
                    translateY: this.offset.y - this.container.y - 1,
                    scale: Math.round(this.offset.width / this.container.width * 1000) / 1000,
                    opacity: 1
                },
                {duration: 100, easing: 'ease', onComplete: done})

            tween.start()
        },

        leave: function (el, done) {
            if (this.state === this.CONST.SHOW) {
                this.$nextTick(() => {
                    this.leaveShow(el, done)
                })
            }
            if (this.state === this.CONST.PREVIEW) {
                this.entered = false
                this.$nextTick(() => {
                    this.leavePreview(el, done)
                })
            }
        },

        enterFade: function (el, done) {
            el.style.opacity = 0

            let tween = KUTE.fromTo(el,
                {
                    opacity: 0
                },
                {
                    opacity: 1
                },

                {duration: 100, easing: 'leaner', onComplete: done})

            tween.start()

        },
        leaveFade: function (el, done) {
            let tween = KUTE.fromTo(el,
                {
                    opacity: 1
                },
                {
                    opacity: 0
                },
                {duration: 50, easing: 'leaner', onComplete: done})

            tween.start()
        },

        afterLeave: function (el) {
            this.entered = false
            this.closeDone()
        },

        beforeEnterHeading: function (el) {
            if (!this.container.width && this.$refs.container) {
                this.container = {
                    x: this.$refs.container.getBoundingClientRect().x,
                    y: this.$refs.container.getBoundingClientRect().y,
                    width: this.$refs.container.getBoundingClientRect().width
                }
            }
            el.style.opacity = 0
        },

        enterHeading: function (el, done) {
            console.log((this.offset.height * (this.n - 1) + 25))
            let tween = KUTE.fromTo(el,
                {
                    translateY: this.offset.y - this.container.y + 1,
                    width: this.offset.width,
                    left: this.offset.x,
                    opacity: 1
                },
                {
                    translateY: this.offset.y - this.container.y - (this.offset.height * (this.n - 1) + 25),
                    width: Math.round(this.offset.width * this.n) - 1,
                    left: this.offset.x - (this.offset.width * this.n - this.offset.width) * 0.5 + 1,
                    opacity: 1
                },

                {duration: 150, delay: 100, easing: 'ease', onComplete: done})

            tween.start()

        },
        leaveHeading: function (el, done) {
            let tween = KUTE.fromTo(el,
                {
                    translateY: this.offset.y - this.container.y - (this.offset.height * (this.n - 1) + 25),
                    width: Math.round(this.offset.width * this.n) - 1,
                    left: this.offset.x - (this.offset.width * this.n - this.offset.width) * 0.5 + 1,
                    opacity: 1
                },
                {
                    translateY: this.offset.y - this.container.y + 1,
                    width: this.offset.width,
                    left: this.offset.x,
                    opacity: 0
                },
                {duration: 50, easing: 'leaner', onComplete: done})

            tween.start()
        },
    }
}

// NOTE: changing the :key runs enter and leave transition on the same time.
// WORKAROUND: make sure a leave transition is shorter than an enter transition
</script>

<template>
    <div>
        <transition key="mask" name="modal" mode="in-out">
            <div class="modal-mask" @keyup.esc="close" @click.self="close" v-show="show"></div>
        </transition>
        <div v-if="post" key="modal" class="modal-wrapper"
             :class="{'modal-preview':preview||show}">
            <div class="modal-dialog" ref="container">

                <transition v-on:before-enter="beforeEnter"
                            v-on:enter="enter"
                            v-on:after-enter="afterEnter"
                            v-on:leave="leave"
                            v-on:afterLeave="afterLeave"
                            appear>
                    <div class="modal-container" :class="{'show':show}" v-show="show||preview">
                        <div class="modal-header" v-show="shown">
                            <span class="modal-close" @click="close"><icon-close/></span>
                        </div>

                        <div class="preview" @click="open">

                            <figure class="preview__video" :style="{'--preview-video-after-height':preview?'100px':''}">
                                <keep-alive>
                                    <video-player :key="`vid-${post.id}`" :playerPause="pause" :autoplay="true"
                                                  :video="{video_file:post.trailer||post.video_file, video_image: post.thumbnail.sizes.miniature}"
                                                  :muted="mute" :loop="false" :controls="false" @canplay="checkCanPlay"/>
                                </keep-alive>
                            </figure>

                            <transition v-on:enter="enterFade" v-on:leave="leaveFade">
                                <div class="preview__wrap" v-show="show">
                                    <div key="head" class="preview__header">
                                        <div>
                                            <h3 class="preview__title">{{ post.title.rendered || post.title }}</h3>
                                            <div class="preview__action">
                                                <router-link class="button overview__btn-play overview__btn-light"
                                                             :to="{name:route.WATCH, params:{id:post.id}}"><span
                                                    class="btn-bg"></span><span>{{
                                                        labels.trailer.button || text.trailer.button
                                                    }}</span>
                                                </router-link>
                                                <span class="player-control-button volume" @click="muteToggle">
                                                    <icon-mute v-show="mute"/>
                                                    <icon-volume-down v-show="!mute"/>
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                    <div key="body" class="preview__body">
                                        <div>
                                            <h3 v-if="post.description_heading">{{ post.description_heading }}</h3>
                                            {{ post.description }}
                                        </div>
                                        <div v-if="post.credits">
                                            <h3>{{ labels.trailer.credits || text.trailer.credits }}</h3>
                                            {{ post.credits }}
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>

                    </div>
                </transition>
                <transition v-on:before-enter="beforeEnterHeading" v-on:enter="enterHeading" v-on:leave="leaveHeading">
                    <div class="preview-heading" v-show="preview">
                        <div class="preview-heading__inner">
                            <icon-play-circle/>
                            {{ post.title.rendered || post.title }}
                        </div>
                    </div>
                </transition>
            </div>

        </div>
    </div>
</template>

<style lang="scss" scoped>
.modal-container {
    background: unset;
    transform-origin: top center;
}

.modal-container.show {
    max-height: calc(var(--windowHeight, 100vh) - 66px);
    overflow-y: auto;
}

.modal-active {
}

.modal-mask {
}

.modal-leave-active {
}

.preview {
    max-width: 806px;
    transition-duration: 0.1s;
    transform-origin: top center;
    position: relative;
    z-index: 2;

    &__video {
        pointer-events: none;
        --preview-video-after-height: 100px;
    }

    &__header,
    &__body {
        pointer-events: auto;
    }

    &__wrap {
        position: relative;
        z-index: 1;
        transform-origin: center;
    }
}

.preview::v-deep {
    .video-wrapper-inner {
        position: relative;
        width: 100%;

        .vjs-player {
            width: 100%;
            max-width: 100%;
            height: 100%;

            .vjs-tech {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .vjs-poster {
            background-size: cover;
        }
    }

    .player-controls-overlay {
        display: none;
    }
}
</style>